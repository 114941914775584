import React from 'react';
import { Toolbar, Grid, Box } from '@mui/material';
import { useAuthStore } from '../../../store/useAuthStore';
import { NotificationMenu } from '../../NotificationMenu';
import { LanguageSelector } from '../LanguageSelector';
import logo from '../../../_assets/logo.png';
import { BackButtonFL } from '../../../components/ui/BackButtonFL';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMobileDetect } from '../../../hooks';

import * as S from './styles';

export const Header = () => {
    const { pathname: currentPath } = useLocation();
    const navigate = useNavigate();
    const creator = useAuthStore((state) => state.creator);
    const isMobile = useMobileDetect();

    const handleGoBack = () => {
        navigate(-1);
    };

    const isHome = currentPath === '/';

    return (
        <S.StyledAppBar position="static">
            <Toolbar>
                {isMobile ? (
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>{!isHome && <BackButtonFL handleGoBack={handleGoBack} />}</Grid>
                        <Grid item>
                            <S.LogoContainer>
                                <S.Logo src={logo} alt="logo" />
                            </S.LogoContainer>
                        </Grid>
                        <Grid item>
                            <Box display="flex" alignItems="center">
                                {creator ? <NotificationMenu /> : <LanguageSelector />}
                            </Box>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <BackButtonFL handleGoBack={handleGoBack} />
                        </Grid>
                        <Grid item xs={8}>
                            <S.LogoContainer>
                                <S.Logo src={logo} alt="logo" />
                            </S.LogoContainer>
                        </Grid>
                        <Grid item>
                            <S.MenuContainer>{creator ? <NotificationMenu /> : <LanguageSelector />}</S.MenuContainer>
                        </Grid>
                    </Grid>
                )}
            </Toolbar>
        </S.StyledAppBar>
    );
};

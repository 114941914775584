import React, { useMemo } from 'react';
import { Route, Routes as RouterRoutes, useLocation } from 'react-router-dom';

import { Dashboard } from '../pages/dashboard/Dashboard';
import { Campanha } from '../components/campanhas/Campanha';
import { Campanhas } from '../components/campanhas/Campanhas';
import { ForgotPassword } from '../pages/forgotPassword';
import { Onboarding } from '../pages/onboarding';
import { CreateNewPassword } from '../pages/createNewPassword';
import { EditUserInfo } from '../pages/editUserInfo';
import { Community } from '../pages/community';
import { CommunitiesList } from '../pages/communityList';
import { Learn } from '../pages/learn';
import { PostDetails } from '../pages/postDetails';
import { NewPost } from '../pages/newPost';

const dashboardRoutes = [
    { path: '/', element: <Onboarding /> },
    { path: '/campanhas', element: <Campanhas /> },
    { path: '/campanhas/:id', element: <Campanha /> },
    { path: '/editUserInfo', element: <EditUserInfo /> },
    { path: '/aprenda', element: <Learn /> },
    { path: '/comunidades', element: <CommunitiesList /> },
    { path: '/comunidade/:id', element: <Community /> },
    { path: '/post/:id', element: <PostDetails /> },
    { path: '/novoPost/:id', element: <NewPost /> },
];

export const Routes = () => {
    const { pathname: currentPath } = useLocation();
    const token = useMemo(() => currentPath.split('/').pop(), [currentPath]);

    return (
        <RouterRoutes>
            {dashboardRoutes.map(({ path, element }) => (
                <Route key={path} path={path} element={<Dashboard>{element}</Dashboard>} />
            ))}
            <Route path="/forgotPassword" element={<ForgotPassword />} />
            <Route path="/createNewPassword" element={<CreateNewPassword />} />
            <Route path={`/createNewPassword/${token}`} element={<CreateNewPassword />} />
        </RouterRoutes>
    );
};

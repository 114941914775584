import { useEffect, useState } from 'react';
import { Avatar, Box, Grid, Link, TextField, Typography, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff, LockOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useAuthStore } from '../../store/useAuthStore';
import { mensagemVazio, showMessage } from '../../data/Interfaces';
import { Loader } from '../../components/ui/Loader';
import { Message } from '../../components/ui/Message';
import { ButtonFL } from '../../components/ui/ButtonFL';
import { alertColor, removeSpacesFromString, emailValidator } from '../../utils';
import backgroundImage from '../../_assets/images/bck-omnicontent.png'; // Importando a imagem

export const Login = () => {
    const login = useAuthStore((state) => state.login);

    const [message, setMessage] = useState(mensagemVazio);
    const [isLoading, setIsLoading] = useState(true);
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState('');

    const { t } = useTranslation('translation', { keyPrefix: 'login' });

    const { message: messageAlert, variant } = message;

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const onChangeEmail = (event) => {
        const email = event.target.value;
        setIsEmailValid(emailValidator(email));
    };

    const onChangePassword = (event) => {
        const password = event.target.value;
        setPassword(password);
        setIsPasswordValid(password.length > 5);
    };

    const validateAndEnableButton = () => {
        return isEmailValid && isPasswordValid;
    };

    const handleSubmit = async (event) => {
        try {
            setIsLoading(true);
            event.preventDefault();

            const data = new FormData(event.currentTarget);

            const email = removeSpacesFromString(data.get('email'));
            const password = removeSpacesFromString(data.get('password'));
            await login(email, password);
        } catch (error) {
            showMessage(
                {
                    variant: alertColor.error,
                    message: error.message,
                },
                setMessage,
            );
        } finally {
            setPassword('');
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setIsLoading(false);
    }, []);

    return (
        <>
            {isLoading && <Loader />}

            {messageAlert && <Message variant={variant} message={messageAlert} />}
            <Grid container sx={{ height: '100vh' }}>
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        backgroundImage: `url(${backgroundImage})`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        height: { xs: '35%', md: '100%' },
                        backgroundColor: 'var(--primary-dark-color)',
                    }}
                />

                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'var(login-color)',
                        padding: 4,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '100%',
                            maxWidth: 400,
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'var(--primary-color)' }}>
                            <LockOutlined />
                        </Avatar>
                        <Typography variant="h4">{t('title')}</Typography>
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="email"
                                label={t('username')}
                                type="text"
                                id="email"
                                onChange={onChangeEmail}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label={t('password')}
                                type={showPassword ? 'text' : 'password'}
                                id="password"
                                value={password}
                                autoComplete="current-password"
                                onChange={onChangePassword}
                                inputProps={{ minLength: 8 }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end" sx={{ marginRight: 1 }}>
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={togglePasswordVisibility}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <ButtonFL
                                buttonDisabled={!validateAndEnableButton()}
                                textKey="signbutton"
                                keyPrefix="login"
                                type="submit"
                            />
                            <Grid container>
                                <Grid item xs>
                                    <Link
                                        underline="none"
                                        href="/forgotPassword"
                                        variant="body2"
                                        sx={{ color: 'var(--primary-color)' }}
                                    >
                                        {t('forgotbutton', { returnObjects: true }).map((line, index) => (
                                            <>
                                                {line}
                                                {index === 0 && <br />}
                                            </>
                                        ))}
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

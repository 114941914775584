import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import CampaignIcon from '@mui/icons-material/Campaign';
import ForumIcon from '@mui/icons-material/Forum';
import SchoolIcon from '@mui/icons-material/School';
import ChatIcon from '@mui/icons-material/Chat';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { ProfileMenu } from '../../components/ui/ProfileMenu';
import { useAuthStore } from '../../store/useAuthStore';

export const DashboardBottomBar = () => {
    const navigate = useNavigate();

    const [value, setValue] = useState(0);
    const [openDrawerMobile, setOpenDrawerMobile] = React.useState(false);

    const creator = useAuthStore((state) => state.creator);

    const iconStyles = { color: 'var(--white-color)' };
    const selectedStyles = { color: 'var(--white-color)', '&.Mui-selected': { color: 'var(--white-color)' } };

    const handleNavigation = (path) => {
        navigate(path);
    };

    const toggleDrawerMobile = (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpenDrawerMobile(!openDrawerMobile);
    };

    return (
        <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue);
            }}
            sx={{
                position: 'fixed',
                bottom: 0,
                width: '100%',
                zIndex: 99999,
                backgroundColor: 'var(--primary-color)',
            }}
        >
            <BottomNavigationAction
                label="Home"
                icon={<HomeIcon sx={iconStyles} />}
                onClick={() => handleNavigation('/')}
                sx={selectedStyles}
            />
            <BottomNavigationAction
                label="Tarefas"
                icon={<CampaignIcon sx={iconStyles} />}
                onClick={() => handleNavigation('/campanhas')}
                sx={selectedStyles}
            />
            <BottomNavigationAction
                label="Comuninades"
                icon={<ForumIcon sx={iconStyles} />}
                onClick={() => handleNavigation('/comunidades')}
                sx={selectedStyles}
            />
            <BottomNavigationAction
                label="Aprenda"
                icon={<SchoolIcon sx={iconStyles} />}
                onClick={() => handleNavigation('/aprenda')}
                sx={selectedStyles}
            />
            <BottomNavigationAction
                label="Perfil"
                icon={<AccountCircleIcon sx={iconStyles} />}
                onClick={toggleDrawerMobile}
                sx={selectedStyles}
            />
            <ProfileMenu openDrawer={openDrawerMobile} toggleDrawer={toggleDrawerMobile} creator={creator} />
        </BottomNavigation>
    );
};

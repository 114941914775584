import React, { useEffect, useState } from 'react';
import { apiGetCommunities } from '../../services';
import { useAuthStore } from '../../store/useAuthStore';
import { Box, List, ListItem, ListItemButton, ListItemAvatar, ListItemText, Avatar, Divider } from '@mui/material';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import * as S from './styles';
import { apiPostJoinCommunity } from '../../services/apiPostJoinCommunity';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../components/ui/Loader';
import { useErrorStore } from '../../store/useErrorStore';

export const CommunitiesList = () => {
    const creator = useAuthStore((state) => state.creator);
    const showError = useErrorStore((state) => state.showError);
    const navigate = useNavigate();

    const [updated, setUpdated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [myCommunities, setMyCommunities] = useState([]);
    const [suggestedCommunities, setSuggestedCommunities] = useState([]);

    const joinCommunity = async (community) => {
        try {
            await apiPostJoinCommunity(community.id, creator.id);
        } catch (error) {
            showError(error);
        } finally {
            setUpdated((prevUpdated) => !prevUpdated);
        }
    };

    const openCommunity = (community) => {
        console.log('community list', community);
        navigate(`/comunidade/${community?.id}`);
    };

    const renderCommunityItem = (community, isSuggested = false) => (
        <ListItem
            key={community?.id}
            disablePadding
            secondaryAction={
                isSuggested ? (
                    <S.JoinButton variant="contained" size="small" onClick={() => joinCommunity(community)}>
                        <PersonAddAltIcon fontSize="small" />
                    </S.JoinButton>
                ) : (
                    <ChevronRightIcon />
                )
            }
        >
            <ListItemButton onClick={() => openCommunity(community)}>
                <ListItemAvatar>
                    <Avatar />
                </ListItemAvatar>
                <ListItemText primary={community.titulo} secondary={`${community?.creators?.length} membros`} />
            </ListItemButton>
        </ListItem>
    );

    useEffect(() => {
        const fetchCommunities = async () => {
            try {
                setIsLoading(true);
                const dataComunidades = await apiGetCommunities();

                const loggedInUserId = creator.id;

                const myComms = dataComunidades.filter((community) =>
                    community.creators.some((creator) => creator.id === loggedInUserId),
                );

                const suggestedComms = dataComunidades.filter(
                    (community) =>
                        community.creators.every((creator) => creator.id !== loggedInUserId) &&
                        community.visibility === 'PUBLIC',
                );

                setMyCommunities(myComms);
                setSuggestedCommunities(suggestedComms);
            } catch (error) {
                showError(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchCommunities();
    }, [updated]);

    return (
        <Box sx={{ px: 2, mt: 3 }}>
            {isLoading && <Loader />}
            <S.Container>
                <S.Title>Minhas Comunidades</S.Title>
                <S.Subtitle>Comunidades que você participa</S.Subtitle>
                <List>
                    {myCommunities.length > 0 && myCommunities.map((community) => renderCommunityItem(community))}
                </List>
            </S.Container>

            {suggestedCommunities && suggestedCommunities.length > 0 && (
                <S.Container>
                    <S.Title>Comunidades Sugeridas</S.Title>
                    <S.Subtitle>Baseadas nos seus interesses</S.Subtitle>
                    <List>
                        {suggestedCommunities.map((community) => (
                            <React.Fragment key={community.name}>
                                {renderCommunityItem(community, true)}
                                <Divider variant="inset" component="li" />
                            </React.Fragment>
                        ))}
                    </List>
                </S.Container>
            )}
        </Box>
    );
};

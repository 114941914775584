import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { App } from './App';
import './i18n';

if (process.env.REACT_APP_ENVIRONMENT) {
    import(`./_assets/css/${process.env.REACT_APP_ENVIRONMENT?.toLocaleLowerCase()}/global.css`);

    console.log('Current Environment:', process.env.REACT_APP_ENVIRONMENT);
} else {
    console.log('import global');
    import('./_assets/css/global.css');
}

const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient();

root.render(
    <QueryClientProvider client={queryClient}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </QueryClientProvider>,
);

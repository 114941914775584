import React from 'react';
import { Box, Typography } from '@mui/material';

import trophy from '../../../_assets/images/trophy.png';

import * as S from './styles';

export const RankingCard = ({ community }) => {
    if (community?.ranking === false) return <></>;

    return (
        <S.RankingButton variant="outlined" onClick={() => {}}>
            <S.TrophyImage src={trophy} alt="Troféu de Ranking" />

            <Box display="flex" flexDirection="column" alignItems="flex-start">
                <Typography
                    variant="h5"
                    letterSpacing={1}
                    sx={{
                        color: 'var(--primary-dark-color)',
                    }}
                >
                    Ranking de Desafios
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        color: 'var(--primary-dark-color)',
                    }}
                >
                    Veja sua posição no ranking
                </Typography>
            </Box>
        </S.RankingButton>
    );
};

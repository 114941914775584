import React, { useEffect, useState } from 'react';
import { apiGetLearn } from '../../services';
import { useAuthStore } from '../../store/useAuthStore';
import { Box, List, ListItem, ListItemButton, ListItemAvatar, ListItemText, Divider } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import * as S from './styles';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../components/ui/Loader';
import { useErrorStore } from '../../store/useErrorStore';

export const Learn = () => {
    const creator = useAuthStore((state) => state.creator);
    const showError = useErrorStore((state) => state.showError);
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [learnList, setLearnList] = useState([]);

    const openLearn = (learn) => {
        console.log('learn list', learn);
        // navigate(`/comunidade/${learn?.id}`);
    };

    const renderLearbItem = (learn) => (
        <ListItem key={learn?.id} disablePadding secondaryAction={<ChevronRightIcon />}>
            <ListItemButton onClick={() => openLearn(learn)}>
                <ListItemText primary={learn?.titulo} secondary={`${learn?.creators?.length} membros`} />
            </ListItemButton>
        </ListItem>
    );

    useEffect(() => {
        const fetchLearn = async () => {
            try {
                setIsLoading(true);
                const data = await apiGetLearn();
                console.log('data', data);
                setLearnList(data);
            } catch (error) {
                console.log('error', error);

                showError(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchLearn();
    }, []);

    return (
        <Box sx={{ px: 2, mt: 3 }}>
            {isLoading && <Loader />}
            <S.Container>
                <S.Title>Aprenda</S.Title>
                <S.Subtitle>Trilhas de aprendizado</S.Subtitle>
                <List>
                    {learnList.length > 0 ? (
                        learnList.map((learn) => renderLearbItem(learn))
                    ) : (
                        <S.WhithoutVideosText>Sem videos no momento.</S.WhithoutVideosText>
                    )}
                </List>
            </S.Container>
        </Box>
    );
};

import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Post } from '../../components/post';
import { RankingCard } from './rankingCard';
import { Opportunities } from './opportunities';
import { useParams } from 'react-router-dom';
import { apiGetCommunity } from '../../services/apiGetCommunity';
import { useErrorStore } from '../../store/useErrorStore';
import { Loader } from '../../components/ui/Loader';

const opportunities = [
    {
        title: 'Promoção para Mequi',
        description: 'Criar um vídeo divertido destacando o novo sanduíche promocional do McDonald’s.',
    },
    {
        title: 'Campanha Wizard Idiomas',
        description: 'Desenvolver conteúdo promovendo um desafio de aprendizado de 30 dias.',
    },
    {
        title: 'Store para Nivea',
        description: 'Fazer um vídeo mostrando os benefícios do creme facial para diferentes tipos de pele.',
    },
    {
        title: 'Evento Riot Games',
        description: 'Cobrir e comentar as finais do campeonato de eSports do League of Legends.',
    },
    {
        title: 'Colaboração com Adidas',
        description: 'Criar conteúdo fitness mostrando como usar novos equipamentos de treino.',
    },
];

export const Community = ({ items = [] }) => {
    const [isLoading, setIsLoading] = useState(null);
    const [community, setCommunity] = useState(null);
    const { id } = useParams();

    const showError = useErrorStore((state) => state.showError);

    const fetchData = async () => {
        try {
            setIsLoading(true);

            if (id) {
                const dataCommunity = await apiGetCommunity(id);
                console.log('dataCommunity >>>>', dataCommunity);

                setCommunity(dataCommunity);
            }
        } catch (error) {
            showError(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const effect = async () => {
            await fetchData();
        };
        effect();
    }, [id]);

    return (
        <Box sx={{ padding: 3 }}>
            {!community || (isLoading && <Loader />)}
            <Typography variant="h4" sx={{ my: 2 }}>
                {community?.titulo}
            </Typography>
            <Opportunities opportunities={opportunities} />
            <RankingCard community={community} />
            <Post community={community} />
        </Box>
    );
};

import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';

export const VideoPlayer = ({ url, showControls }) => {
    const [handlePlaying, setHandlePlaying] = useState(false);

    const handlePlay = () => {
        setHandlePlaying(true);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [url]);

    return (
        <>
            <ReactPlayer
                url={url}
                controls={showControls}
                playing={handlePlaying}
                onClickPreview={handlePlay}
                width="100%"
                height="100%"
                onReady={() => console.log('Video is buffering')}
                onPlay={handlePlay}
            />
        </>
    );
};

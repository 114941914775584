import React from 'react';
import { Box, Typography, ButtonBase } from '@mui/material';
import { ButtonFL } from '../../../components/ui/ButtonFL';

import * as S from './styles';

export const Opportunities = ({ opportunities = [] }) => {
    const truncateDescription = (text, isTitle) => {
        const maxLength = isTitle ? 22 : 50;
        if (text.length <= maxLength) return text;
        return text.substring(0, maxLength - 3) + '...';
    };
    return (
        <S.Container>
            <S.Title variant="h5">Oportunidades em Destaque</S.Title>
            <S.ScrollContainer>
                {opportunities?.map((opportunity, index) => (
                    <ButtonBase
                        key={index}
                        onClick={() => {
                            console.log('opportunity', opportunity);
                        }}
                    >
                        <S.OpportunityItem>
                            <Box>
                                <S.BoldText>{truncateDescription(opportunity.title, true)}</S.BoldText>
                                <S.Subtitle>{truncateDescription(opportunity.description, false)}</S.Subtitle>
                            </Box>
                            <Box sx={{ mb: 1 }}>
                                <Typography variant="caption" color="var( --success-color)">
                                    3 vagas disponíveis
                                </Typography>
                            </Box>
                            <ButtonFL textKey="Inscrições" />
                        </S.OpportunityItem>
                    </ButtonBase>
                ))}
            </S.ScrollContainer>
        </S.Container>
    );
};

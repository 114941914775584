import React, { useMemo, useState, useEffect } from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    CardMedia,
    Avatar,
    IconButton,
    Typography,
    Grid,
    Fab,
    Chip,
} from '@mui/material';
import { Favorite, ChatBubbleOutline, Add as AddIcon, FavoriteBorder } from '@mui/icons-material';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { usePosts } from '../../hooks';
import { capitalizerFirstLetter } from '../../utils';
import { Loader } from '../../components/ui/Loader';
import { apiPostLike, apiDeleteLike } from '../../services';
import { useAuthStore } from '../../store/useAuthStore';
import { useErrorStore } from '../../store/useErrorStore';

export const Post = ({ community }) => {
    const navigate = useNavigate();
    const creator = useAuthStore((state) => state.creator);
    const showError = useErrorStore((state) => state.showError);

    const { data: postsData, fetchNextPage, hasNextPage, isLoading, error } = usePosts(community?.id);

    const posts = useMemo(() => postsData?.pages?.flatMap((page) => page) ?? [], [postsData]);
    console.log('postsData', postsData);
    const [likedPosts, setLikedPosts] = useState({});

    useEffect(() => {
        const initialLikedPosts = {};
        posts.forEach((post) => {
            const isLikedByUser = post?.engajamento?.likes.some((like) => like?.creator === creator.id);
            initialLikedPosts[post.id] = {
                isLiked: isLikedByUser,
                likesCount: post.engajamento?.likes?.length,
            };
        });
        setLikedPosts(initialLikedPosts);
    }, [posts, creator.id]);

    const handleLike = async (postId) => {
        const isCurrentlyLiked = likedPosts[postId]?.isLiked;

        try {
            if (isCurrentlyLiked) {
                await apiDeleteLike({ idPost: postId, creator: creator.id });
                setLikedPosts((prev) => ({
                    ...prev,
                    [postId]: {
                        isLiked: false,
                        likesCount: prev[postId].likesCount - 1,
                    },
                }));
            } else {
                await apiPostLike({ idPost: postId, creator: creator.id });
                setLikedPosts((prev) => ({
                    ...prev,
                    [postId]: {
                        isLiked: true,
                        likesCount: prev[postId].likesCount + 1,
                    },
                }));
            }
        } catch (error) {
            showError(error);
        }
    };

    const handlePostClick = (post) => {
        navigate(`/post/${post.id}`);
    };

    if (error) return <p>Erro ao carregar posts.</p>;

    return (
        <div style={{ position: 'relative', minHeight: '100vh' }}>
            {isLoading && <Loader />}
            <InfiniteScroll
                dataLength={posts?.length}
                next={fetchNextPage}
                hasMore={hasNextPage}
                loader={<p>Carregando mais...</p>}
                endMessage={
                    <p style={{ textAlign: 'center' }}>
                        <b>Você chegou ao final!</b>
                    </p>
                }
            >
                <Grid container spacing={2} marginTop={4} marginBottom={10}>
                    {posts.map((post) => (
                        <Grid item xs={12} md={6} lg={3} key={post.id}>
                            {post && (
                                <Card
                                    sx={{
                                        maxWidth: 500,
                                        margin: 'auto',
                                        borderRadius: '16px',
                                        overflow: 'hidden',
                                    }}
                                    onClick={() => handlePostClick(post)}
                                >
                                    <CardHeader
                                        avatar={<Avatar>{post.creator?.nome?.charAt(0) ?? 'A'}</Avatar>}
                                        title={
                                            <Typography variant="subtitle1" fontWeight="bold">
                                                {capitalizerFirstLetter(post.creator?.nome ?? 'Anônimo')}
                                            </Typography>
                                        }
                                        subheader={
                                            post?.createdAt
                                                ? new Date(post?.createdAt).toLocaleString()
                                                : 'Data indisponível'
                                        }
                                    />

                                    {(post?.media?.videoCapa ?? post?.media?.imageUrl) && (
                                        <div
                                            style={{
                                                position: 'relative',
                                                width: '100%',
                                                height: '400px',
                                                borderRadius: '16px',
                                                overflow: 'hidden',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <CardMedia
                                                component="img"
                                                src={post?.media?.videoCapa ?? post?.media?.imageUrl}
                                                style={{
                                                    width: '90%',
                                                    height: '100%',
                                                    objectFit: 'cover',
                                                    borderRadius: '16px',
                                                }}
                                            />
                                            {post?.media?.videoUrl && (
                                                <PlayArrowIcon
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        console.log('Play video');
                                                    }}
                                                    style={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        transform: 'translate(-50%, -50%)',
                                                        fontSize: '64px',
                                                        color: 'white',
                                                        cursor: 'pointer',
                                                        backgroundColor: 'rgba(0, 0, 0, 0.4)',
                                                        borderRadius: '50%',
                                                        padding: '8px',
                                                    }}
                                                />
                                            )}
                                        </div>
                                    )}

                                    <CardContent>
                                        <Grid container spacing={1} justifyContent="flex-start">
                                            <Grid item>
                                                <IconButton>
                                                    <ChatBubbleOutline />
                                                </IconButton>
                                                {String(post?.engajamento?.comentarios?.length ?? 0)}
                                            </Grid>
                                            <Grid item>
                                                <IconButton
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleLike(post.id);
                                                    }}
                                                >
                                                    {likedPosts[post.id]?.isLiked ? (
                                                        <Favorite fontSize="medium" />
                                                    ) : (
                                                        <FavoriteBorder fontSize="medium" />
                                                    )}
                                                </IconButton>
                                                {likedPosts[post.id]?.likesCount ?? 0}
                                            </Grid>
                                        </Grid>
                                    </CardContent>

                                    <CardContent>
                                        <Typography variant="body2">
                                            {post.texto ? capitalizerFirstLetter(post.texto) : ''}
                                        </Typography>

                                        {post.categorias && post.categorias.length > 0 && (
                                            <div
                                                style={{
                                                    marginTop: '25px',
                                                    display: 'flex',
                                                    gap: '8px',
                                                    flexWrap: 'wrap',
                                                }}
                                            >
                                                {post.categorias.map((categoria, index) => (
                                                    <Chip
                                                        key={index}
                                                        label={categoria}
                                                        sx={{
                                                            bgcolor: 'var(--primary-light-color)',
                                                            color: 'var(--white-color)',
                                                        }}
                                                    />
                                                ))}
                                            </div>
                                        )}
                                    </CardContent>
                                </Card>
                            )}
                        </Grid>
                    ))}
                </Grid>
            </InfiniteScroll>

            {community?.id > 0 && (
                <Fab
                    color="primary"
                    aria-label="postar"
                    style={{
                        position: 'fixed',
                        bottom: 80,
                        right: 24,
                        backgroundColor: 'var(--primary-color)',
                        color: 'white',
                        zIndex: 1000,
                    }}
                    onClick={() => navigate(`/novoPost/${community.id}`, { state: { community } })}
                >
                    <AddIcon />
                </Fab>
            )}
        </div>
    );
};

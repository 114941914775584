import { Button } from '@mui/material';
import { styled } from '@mui/system';

export const TrophyImage = styled('img')({
    width: 36,
    height: 36,
    marginRight: 8,
});

export const RankingButton = styled(Button)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 18,
    borderWidth: 1,
    borderColor: 'var(--grey-400-color)',
    padding: theme.spacing(2),
    gap: theme.spacing(2),
    borderStyle: 'solid',
    textTransform: 'none',
    width: '100%',
    marginTop: theme.spacing(3),
}));

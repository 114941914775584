import { styled } from '@mui/system';
import { Card, InputBase, IconButton } from '@mui/material';

export const StyledCard = styled(Card)({
    maxWidth: 400,
    margin: '0 auto',
    backgroundColor: 'var(--grey-50-color)',
    color: 'var(--grey-900-color)',
    overflowY: 'auto',
    marginBottom: '200px',
    boxShadow: 'none',
});

export const VideoWrapper = styled('div')({
    position: 'relative',
    height: '480px',
    borderRadius: 8,
});

export const CommentsContainer = styled('div')({
    padding: 10,
});

export const CommentInput = styled(InputBase)({
    padding: '4px 8px',
    borderRadius: 4,
    marginRight: 8,
    flexGrow: 1,
    border: '1px solid var(--grey-400-color)',
    boxSizing: 'border-box',
    width: '100%',
});

import { styled } from '@mui/system';
import { Box, Typography, Paper } from '@mui/material';

export const Container = styled(Box)(({ theme }) => ({
    backgroundColor: 'var(--grey-50-color)',
    padding: theme.spacing(2),
    borderRadius: 8,
}));

export const Title = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(1),
    fontSize: '1.5rem',
    letterSpacing: '0.05em',
    color: 'var(--primary-dark-color)',
}));

export const ScrollContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    overflowX: 'auto',
    scrollBehavior: 'smooth',
    gap: '12px',
    padding: '8px 0',
    '&::-webkit-scrollbar': {
        height: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        borderRadius: '4px',
    },
    '&::-webkit-scrollbar-track': {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
    },
    [theme.breakpoints.up('md')]: {
        overflowX: 'scroll',
    },
}));

export const OpportunityItem = styled(Paper)(({ theme }) => ({
    width: '200px',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(2),
    borderRadius: 8,
    backgroundColor: 'var(--grey-100-color)',
    border: `1px solid var(--grey-400-color)`,
    boxShadow: 'none',
    overflow: 'hidden',
    cursor: 'pointer',
    transition: 'transform 0.2s ease-in-out',
    '&:hover': {
        transform: 'scale(1.05)',
    },
}));

export const BoldText = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    fontSize: '0.94rem',
    color: 'var(--primary-color)',
    marginBottom: theme.spacing(0.5),
}));

export const Subtitle = styled(Typography)(({ theme }) => ({
    fontSize: '0.85rem',
    color: 'var(--grey-800-color)',
    marginBottom: theme.spacing(0.5),
}));

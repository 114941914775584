import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import {
    Avatar,
    TextField,
    IconButton,
    Box,
    Stack,
    Typography,
    Button,
    Chip,
    FormControl,
    Paper,
    styled,
} from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import TagIcon from '@mui/icons-material/Tag';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import { useAuthStore } from '../../store/useAuthStore';
import { Post } from '../../http/httpVerbs';
import { Loader } from '../../components/ui/Loader';
import { useErrorStore } from '../../store/useErrorStore';
import { ButtonFL } from '../../components/ui/ButtonFL';

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

export const NewPost = () => {
    const { id } = useParams();
    const location = useLocation();
    const { community } = location.state || {};
    const navigate = useNavigate();

    const showError = useErrorStore((state) => state.showError);
    const creator = useAuthStore((state) => state.creator);

    const [isLoading, setIsLoading] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [attachedFile, setAttachedFile] = useState(null);
    const [attachedLink, setAttachedLink] = useState('');
    const [showLinkInput, setShowLinkInput] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState([]);

    const fileInputRef = useRef(null);

    const handleInputChange = (e) => setInputValue(e.target.value);

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file) setAttachedFile(file);
    };
    const handleRemoveFile = () => setAttachedFile(null);

    const handleCategoryClick = (categoryId) => {
        setSelectedCategories((prevSelected) =>
            prevSelected.includes(categoryId)
                ? prevSelected.filter((id) => id !== categoryId)
                : [...prevSelected, categoryId],
        );
    };

    const handleSubmit = async () => {
        if (!inputValue && !attachedFile && !attachedLink) {
            alert('Por favor, preencha algum campo para enviar.');
            return;
        }

        const formData = new FormData();

        formData.append('campanha', Number(id));
        formData.append('texto', inputValue);
        formData.append('creator', creator.id);

        formData.append('categorias', JSON.stringify(selectedCategories));
        formData.append('categorias', JSON.stringify(selectedCategories));

        if (attachedFile) formData.append('file', attachedFile);
        if (attachedLink) formData.append('link', attachedLink);

        for (let [key, value] of formData.entries()) {
            console.log(`${key}:`, value);
        }

        try {
            setIsLoading(true);
            await Post(`${process.env.REACT_APP_API_URL}/posts/`, formData);

            setInputValue('');
            setAttachedFile(null);
            setAttachedLink('');
            setSelectedCategories([]);

            navigate(`/comunidade/${id}`);
        } catch (error) {
            console.log('Erro na requisição:', error);
            showError(error);
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) return <Loader />;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: 4,
                borderRadius: 2,
                backgroundColor: 'var(--grey-50-color)',
                color: 'var(--grey-800-color)',
                height: '100vh',
            }}
        >
            <Stack direction="row" spacing={2} alignItems="center" mb={2}>
                <Avatar>{creator.nome ? creator.nome.charAt(0) : '?'}</Avatar>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    {creator.nome}
                </Typography>
            </Stack>
            <TextField
                variant="outlined"
                placeholder="Digite o conteúdo do seu post aqui"
                fullWidth
                multiline
                rows={3}
                value={inputValue}
                onChange={handleInputChange}
                sx={{
                    backgroundColor: 'var(--white-color)',
                    borderRadius: 1,
                    marginBottom: 2,
                }}
            />
            <Typography variant="body2" sx={{ mb: 1 }}>
                Selecione seu vídeo:
            </Typography>
            <Stack direction="row" spacing={1} mb={1}>
                <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileUpload} />
                <IconButton onClick={() => fileInputRef.current.click()} sx={{ color: 'var(--grey-800-color)' }}>
                    <ImageIcon />
                </IconButton>
                <IconButton onClick={() => setShowLinkInput(true)} sx={{ color: 'var(--grey-800-color)' }}>
                    <TagIcon />
                </IconButton>
            </Stack>

            {attachedFile && (
                <Stack direction="row" alignItems="center" spacing={1} mb={4}>
                    <AttachFileIcon />
                    <Typography variant="body2">{attachedFile.name}</Typography>
                    <IconButton onClick={handleRemoveFile} sx={{ color: 'var(--grey-800-color)' }}>
                        <CloseIcon />
                    </IconButton>
                </Stack>
            )}
            <Typography variant="body2">Selecione uma categoria:</Typography>
            <FormControl fullWidth margin="normal">
                <Paper
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        listStyle: 'none',
                        px: 0.5,
                        m: 0,
                        boxShadow: 'none',
                        backgroundColor: 'var(--grey-50-color)',
                        mb: 10,
                    }}
                    component="ul"
                >
                    {community?.categorias?.map((category) => (
                        <ListItem key={category}>
                            <Chip
                                key={
                                    selectedCategories.includes(category)
                                        ? `selected-${category}`
                                        : `unselected-${category}`
                                }
                                label={category}
                                clickable
                                sx={{
                                    bgcolor: selectedCategories.includes(category)
                                        ? 'var(--primary-light-color)'
                                        : 'var(--grey-300-color)',
                                    color: selectedCategories.includes(category)
                                        ? 'var(--primary-dark-color)'
                                        : 'var(--grey-800-color)',
                                }}
                                onClick={() => handleCategoryClick(category)}
                                onDelete={
                                    selectedCategories.includes(category) ? () => handleCategoryClick(category) : null
                                }
                            />
                        </ListItem>
                    ))}
                </Paper>
            </FormControl>

            <ButtonFL textKey=" Publicar" onClick={handleSubmit} />
        </Box>
    );
};

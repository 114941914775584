import DvrOutlinedIcon from '@mui/icons-material/DvrOutlined';
import HomeIcon from '@mui/icons-material/Home';
import ForumIcon from '@mui/icons-material/Forum';
import { Lightbulb } from '@mui/icons-material';

export const menus = [
    {
        title: 'Home',
        link: '/',
        accessLevel: 0,
        icon: HomeIcon,
    },
    {
        title: 'Tarefas',
        link: '/campanhas',
        accessLevel: 0,
        icon: DvrOutlinedIcon,
    },
    {
        title: 'Comuninades',
        link: '/comunidades',
        accessLevel: 0,
        icon: ForumIcon,
    },
    {
        title: 'Aprenda',
        link: '/aprenda',
        accessLevel: 0,
        icon: Lightbulb,
    },
];

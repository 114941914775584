import { useState, useEffect } from 'react';
import {
    Avatar,
    CardHeader,
    CardContent,
    CardActions,
    Typography,
    IconButton,
    Divider,
    Box,
    CardMedia,
    Chip,
} from '@mui/material';
import { FavoriteBorder, ChatBubbleOutline, Favorite } from '@mui/icons-material';
import { VideoPlayer } from '../../components/ui/VideoPlayer';
import { useParams } from 'react-router-dom';
import { apiGetPost, apiPostLike, apiDeleteLike } from '../../services';
import { useAuthStore } from '../../store/useAuthStore';
import { useErrorStore } from '../../store/useErrorStore';
import { Loader } from '../../components/ui/Loader';
import * as S from './styles';
import { CommentsSheet } from '../../components/commentsSheet';
import { apiPostComment } from '../../services/apiPostComment';

export const PostDetails = () => {
    const creator = useAuthStore((state) => state.creator);
    const showError = useErrorStore((state) => state.showError);

    const { id } = useParams();

    const [isLoading, setIsLoading] = useState(null);
    const [post, setPost] = useState({});
    const [likesCount, setLikesCount] = useState(0);
    const [isLiked, setIsLiked] = useState(false);
    const [videoData, setVideoData] = useState(null);
    const [isCommentsSheetOpen, setIsCommentsSheetOpen] = useState(false);

    const fetchData = async (likeClick = false) => {
        try {
            if (!likeClick) setIsLoading(true);
            if (id) {
                const data = await apiGetPost(id);
                setPost(data);
                setVideoData(data?.media?.videoUrl);
            }
        } catch (error) {
            showError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleAddLike = async () => {
        try {
            const likeData = { idPost: post?.id, creator: creator?.id };
            await apiPostLike(likeData);
            setLikesCount((prev) => prev + 1);
        } catch (error) {
            console.log('Erro', error);
        }
    };

    const handleRemoveLike = async () => {
        try {
            const likeData = { idPost: post?.id, creator: creator.id };
            await apiDeleteLike(likeData);
            setLikesCount((prev) => prev - 1);
        } catch (error) {
            showError(error);
        }
    };

    const handleLikeToggle = async () => {
        if (isLiked) {
            await handleRemoveLike();
        } else {
            await handleAddLike();
        }
        setIsLiked(!isLiked);
        await fetchData(true);
    };

    const sendComment = async (comment) => {
        try {
            setIsLoading(true);

            const dataComment = {
                campanha: post.campanha,
                texto: comment,
                idPostParent: post.id,
                creator: creator.id,
            };

            await apiPostComment(dataComment);
            await fetchData();
        } catch (error) {
            showError(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const effect = async () => {
            await fetchData();
        };
        effect();
    }, [id]);

    useEffect(() => {
        if (post) {
            const likes = post?.engajamento?.likes || [];
            setLikesCount(likes?.length ?? 0);
            setIsLiked(likes?.some((like) => like.creator === creator.id));
        }
    }, [post]);

    return (
        <S.StyledCard>
            {isLoading && <Loader />}
            <CardHeader
                avatar={<Avatar>{post?.creator?.nome?.charAt(0) ?? 'A'}</Avatar>}
                title={
                    <Typography variant="subtitle1" fontWeight="bold">
                        {post?.creator?.nome}
                    </Typography>
                }
                subheader={new Date(post?.createdAt).toLocaleString()}
            />

            <CardContent sx={{ px: 2 }}>
                {!videoData && (post?.media?.videoCapa ?? post?.media?.imageUrl) && (
                    <div
                        style={{
                            position: 'relative',
                            width: '100%',
                            height: '400px',
                            borderRadius: '16px',
                            overflow: 'hidden',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <CardMedia
                            component="img"
                            src={post?.media?.videoCapa ?? post?.media?.imageUrl}
                            style={{
                                width: '90%',
                                height: '100%',
                                objectFit: 'cover',
                                borderRadius: '16px',
                            }}
                        />
                    </div>
                )}

                {videoData && (
                    <S.VideoWrapper>
                        <VideoPlayer url={videoData} showControls />
                    </S.VideoWrapper>
                )}

                <Box sx={{ padding: 2 }}>
                    <Typography
                        variant="body2"
                        sx={{
                            wordWrap: 'break-word',
                            overflowWrap: 'break-word',
                            whiteSpace: 'pre-line',
                            textTransform: 'capitalize',
                            mt: 1,
                        }}
                    >
                        {post?.texto}
                    </Typography>
                </Box>

                <Divider sx={{ bgcolor: 'var(--grey-400-color)' }} />

                {post.categorias && post.categorias.length > 0 && (
                    <div
                        style={{
                            marginTop: '25px',
                            display: 'flex',
                            gap: '8px',
                            flexWrap: 'wrap',
                        }}
                    >
                        {post.categorias.map((categoria, index) => (
                            <Chip
                                key={index}
                                label={categoria}
                                sx={{
                                    bgcolor: 'var(--primary-light-color)',
                                    color: 'var(--white-color)',
                                }}
                            />
                        ))}
                    </div>
                )}

                <CardActions sx={{ justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', gap: 16, alignItems: 'center' }}>
                        <IconButton onClick={() => setIsCommentsSheetOpen(!isCommentsSheetOpen)}>
                            <ChatBubbleOutline fontSize="medium" />
                            <Typography variant="caption" marginLeft={0.5}>
                                {String(post?.engajamento?.comentarios?.length ?? 0)}
                            </Typography>
                        </IconButton>
                        <IconButton
                            onClick={handleLikeToggle}
                            sx={{ color: isLiked ? 'var(--primary-color)' : 'inherit' }}
                        >
                            {isLiked ? <Favorite fontSize="medium" /> : <FavoriteBorder fontSize="medium" />}
                            <Typography variant="caption" marginLeft={0.5}>
                                {likesCount}
                            </Typography>
                        </IconButton>
                    </div>
                </CardActions>
                <S.CommentsContainer>
                    <Typography
                        variant="body1"
                        onClick={() => setIsCommentsSheetOpen(!isCommentsSheetOpen)}
                        style={{ cursor: 'pointer', marginTop: 8 }}
                    >
                        {`Ver todos os ${String(post?.engajamento?.comentarios?.length ?? 0)} comentários`}
                    </Typography>
                </S.CommentsContainer>

                {isCommentsSheetOpen && (
                    <CommentsSheet
                        isOpen={isCommentsSheetOpen}
                        setIsOpen={setIsCommentsSheetOpen}
                        sendComment={sendComment}
                        comments={post?.engajamento?.comentarios || []}
                    />
                )}
            </CardContent>
        </S.StyledCard>
    );
};

import { Get } from '../http/httpVerbs';

export const apiGetPosts = async (page = 1, campanha = '') => {
    try {
        let url = `${process.env.REACT_APP_API_URL}/posts?page=${page}`;

        if (campanha) {
            url += `&campanha=${encodeURIComponent(campanha)}`;
        }

        const response = await Get(url);

        return response;
    } catch (error) {
        console.error('API apiGetPosts ERROR:', error);
        throw error;
    }
};

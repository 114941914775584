import React, { useEffect, useState, useRef } from 'react';
import { Box, Typography, Avatar, IconButton, TextField } from '@mui/material';
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import SendIcon from '@mui/icons-material/Send';
import { capitalizerFirstLetter } from '../../utils';

export const CommentsSheet = ({ isOpen, setIsOpen, comments, sendComment }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [comment, setComment] = useState('');
    const [maxHeight, setMaxHeight] = useState('40vh');

    const bottomSheetRef = useRef();

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const renderComments = () => (
        <>
            <Box sx={{ p: 3, maxHeight, overflowY: 'auto' }}>
                <Typography variant="h6" marginBottom={4}>
                    Comentários
                </Typography>
                {comments.map((comment, index) => (
                    <div key={index} style={{ display: 'flex', alignItems: 'flex-start', marginBottom: 18 }}>
                        <Avatar sx={{ width: 30, height: 30, fontSize: 14, mt: 0.8 }}>
                            {comment.usuario?.nome?.charAt(0) ?? 'A'}
                        </Avatar>
                        <div style={{ marginLeft: 8 }}>
                            <div style={{ marginBottom: 5 }}>
                                <Typography variant="subtitle1" letterSpacing={0.7} sx={{ lineHeight: 1 }}>
                                    {comment.usuario?.nome || comment.creator?.nome || 'Anônimo'}
                                </Typography>
                                <Typography variant="caption" sx={{ color: 'var(--grey-100-color' }}>
                                    {new Date(comment?.createdAt).toLocaleString()}
                                </Typography>
                            </div>
                            <Typography variant="body1">{capitalizerFirstLetter(comment.texto)}</Typography>
                        </div>
                    </div>
                ))}
            </Box>
        </>
    );

    const handleSendComment = async () => {
        await sendComment(comment);
        setComment('');
    };

    return isMobile ? (
        <BottomSheet
            open={isOpen}
            onDismiss={() => setIsOpen(false)}
            ref={bottomSheetRef}
            snapPoints={({ maxHeight }) => [0.6 * maxHeight, maxHeight]}
            defaultSnap={({ maxHeight }) => 0.6 * maxHeight}
            onSpringEnd={(event) => {
                if (event.type === 'SNAP' && event.source === 'drag') {
                    setMaxHeight(event.to === 1 ? '80vh' : '40vh');
                }
            }}
            style={{
                overflowY: 'auto',
                backgroundColor: 'var(--grey-50-color)',
                position: 'relative',
                maxWidth: '400px',
                margin: '0 auto',
            }}
        >
            {renderComments()}
            <Box
                sx={{
                    position: isMobile ? 'fixed' : 'relative',
                    bottom: 60,
                    backgroundColor: 'var(--grey-50-color)',
                    width: '100%',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '0 10px',
                    }}
                >
                    <Box style={{ flex: 4, marginRight: 8 }}>
                        <TextField
                            placeholder="Adicionar um comentário..."
                            style={{ width: '100%', padding: 10 }}
                            onChange={(e) => setComment(e.target.value)}
                            value={comment}
                        />
                    </Box>
                    <div
                        style={{
                            flex: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            marginLeft: 10,
                            alignItems: 'center',
                        }}
                    >
                        <IconButton
                            sx={{ borderRadius: '50%', backgroundColor: 'var(--primary-color)', color: 'white' }}
                            onClick={handleSendComment}
                        >
                            <SendIcon sx={{ fontSize: 12 }} />
                        </IconButton>
                    </div>
                </div>
            </Box>
        </BottomSheet>
    ) : (
        <>
            {renderComments()}
            <Box sx={{ position: 'relative', backgroundColor: 'var(--grey-50-color)', width: '100%' }}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '0 16px',
                    }}
                >
                    <div style={{ flex: 4, marginRight: 8 }}>
                        <TextField
                            placeholder="Adicionar um comentário..."
                            sx={{ width: '100%' }}
                            onChange={(e) => setComment(e.target.value)}
                            value={comment}
                        />
                    </div>
                    <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                        <IconButton
                            sx={{ borderRadius: '50%', backgroundColor: 'var(--primary-color)', color: 'white' }}
                            onClick={handleSendComment}
                        >
                            <SendIcon sx={{ fontSize: 12 }} />
                        </IconButton>
                    </div>
                </div>
            </Box>
        </>
    );
};
